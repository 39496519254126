/** @jsx jsx */

import { FC } from 'react'
import { jsx } from 'theme-ui'

import Halo from '../../components/halo'
import Intro from '../../components/intro'
import Layout from '../../components/layout'
import MarketoForm from '../../components/marketo-form'
import { halo, intro } from '../../content/private-companies-request-information'

const PrivateCompaniesRequestInformation: FC = () => {
  return (
    <Layout>
      <Halo {...halo} />
      <Intro {...intro} />
      <MarketoForm formId="4395" containerStyles={{ py: 5, bg: `white_slate8` }} />
    </Layout>
  )
}

export default PrivateCompaniesRequestInformation
